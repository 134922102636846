import request from "../../utils/request";

 //根据过滤条件，获取游戏信息
 export const getHelpCenterList = (data)=>{
    return  request({
         method:'GET',
         url:'/game/getHelpCenterList',
         data
     })
 }
