<template>
    <div class="container">
         <div class="content">
           <div class="content-left">
              <!-- 常见问题 -->
              <list-menu v-for="(item,index) in menuTitleAndContentData" :key="index" :mianTitleIndex="index" :menuTitleData="item" @currentClickMenuIndex="currentClickMenuIndex"></list-menu>
              <!-- QQ账号须知 -->
              <!-- <list-menu :menuTitleData="qqAccountMenuTitleData" @currentClickMenuIndex="currentClickMenuIndex"></list-menu> -->
              <!-- 微信账号须知 -->
              <!-- <list-menu :menuTitleData="wxAccountMenuTitleData" @currentClickMenuIndex="currentClickMenuIndex"></list-menu> -->

           </div>
           <div class="content-right">
             <list-content :mainTitle="currentMainTitleData" :contentData="currentContentData" ></list-content>
           </div>
         </div>
         <content-footer></content-footer> 
    </div>
  </template>
  
  <script>
  import ListMenu from './components/list-menu.vue';
  import ListContent from './components/list-content.vue';
  import ContentFooter from '../home/component/content-footer.vue';
  import {getHelpCenterList} from '../../services/helpCenter'
  export default {
      name:'HelpCenter',
      components:{
          ListMenu,
          ListContent,
          ContentFooter
      },
      data(){
        return{
          menuTitleAndContentData:[],
          currentMainTitleData:undefined,
          currentContentData:undefined,
        }
      },
      methods:{
        //当前点击的菜单
        currentClickMenuIndex(mianTitleIndex,subTitleIndex){
          // console.log('当前主菜单索引---'+mianTitleIndex);
          // console.log('当前点击的子菜单索引是---'+subTitleIndex)
          let tempArray = JSON.stringify(this.menuTitleAndContentData[mianTitleIndex].subItemData.filter(item=>
            item.id==subTitleIndex //寻找子菜单的id等于当前点击的子菜单索引：subTitleIndex
          ));
          this.currentContentData = JSON.parse(tempArray.substring(1,tempArray.length-1));
          this.currentMainTitleData = this.menuTitleAndContentData[mianTitleIndex].mainTitle;
        },
        //加载帮助中心列表
        async loadHelpCenterList(){
          try{
            const {data} = await getHelpCenterList();
            if(data.code==200){
              this.menuTitleAndContentData = data.data;
              this.currentClickMenuIndex(0,1); //放在成功获取数据之后
            }
          }catch(err){}
        }
        
      },
      mounted(){
        this.loadHelpCenterList();
        window.scrollTo(0,0);

      },
      beforeDestroy(){
        //去掉左边菜单当前索引，因为它是全局的
        this.$store.commit('helpCenter/setCurrentMenuIndex',0)
      }
  }
  </script>
  
  <style lang="less" scoped>
  .container{
      background:#F5F5F5;
      width: calc(100vw);
      min-width: 1440px;
      .content{
          margin: 0 auto;
          width: 1200px;
          display: flex;
          justify-content: space-between;
          .content-left{
            margin-top: 20px;
            width: 280px;
            min-height: 890px;
            border-radius: 6px;
            background-color: #FFFFFF;

          }
          .content-right{
            margin-top: 20px;
            padding: 20px;
            width: 900px;
            border-radius: 6px;
            background: #FFFFFF;
            
          }
      }
  }
  
  </style>